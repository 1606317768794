import SideBarFilters from '@/components/Filters/SideBarFilters';
import SearchResultContainer from '@/components/Search/SearchResults/SearchResultContainer';
import usePageVisitTracker from '@/hooks/tracking/usePageVisitTracker';
import useFilters from '@/hooks/useFilters';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { useSearchFiltersContext } from '@/providers/searchFiltersProvider';
import { useSearchContext } from '@/providers/searchProvider';
import { ToastContainer } from 'react-toastify';

export default function SearchPage() {
  usePageVisitTracker('Search');
  const { filters, setFilters } = useSearchFiltersContext();
  const { selectedResult, setSelectedResult, hasSearched } =
    useSearchContext();
  const { handleSelect, handleDeselect, handleSelectAll, handleDeselectAll } =
    useFilters({ setFilters });
  const {
    results,
    summary,
    summaryBullets,
    error,
    loadingResults,
    loadingSummary,
    pageNumber,
    pageSize,
    setPageNumber,
  } = useSearchContext();

  return (
    <NavLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col">
        <div className="h-full flex-1 overflow-hidden">
          <div className="flex h-full flex-1 overflow-hidden pt-1">
            <div className="w-[15%]">
              <SideBarFilters
                filters={filters}
                handleSelectAll={handleSelectAll}
                handleDeselectAll={handleDeselectAll}
                handleSelect={handleSelect}
                handleDeselect={handleDeselect}
              />
            </div>
            <div className="flex w-[85%]">
              <div className="relative mx-auto max-w-7xl flex-1 justify-center">
                <SearchResultContainer
                  results={results}
                  summary={summary}
                  bullets={summaryBullets?.bullets ?? []}
                  selectedResult={selectedResult}
                  setSelectedResult={setSelectedResult}
                  error={error}
                  query={''}
                  page={pageNumber}
                  pageSize={pageSize}
                  setPage={setPageNumber}
                  hasSearched={hasSearched}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavLayout>
  );
}
