import {
  getSearchContent,
  QueryParams,
  querySearchStream,
  SearchResponse,
  SortField,
  SummarizeParams,
  summarizeSearch,
} from '@/api/search';
import { CitedBullet, Summary } from '@/types';
import { SortParams } from '@/types/api';
import { SearchResult, SelectedSearchResult } from '@/types/search';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef, useState } from 'react';

const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_PAGE_NUMBER = 1;

export const useSearch = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [summary, setSummary] = useState<string | null>(null);
  const [summaryBullets, setSummaryBullets] = useState<SummaryBullets | null>(
    null,
  );
  const [error, setError] = useState<Error | null>(null);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [articleContents, setArticleContents] = useState<
    Map<string, string[]>
  >(new Map());
  const [loadingArticleContents, setLoadingArticleContents] = useState<
    Set<string>
  >(new Set());
  const [selectedResult, setSelectedResult] =
    useState<SelectedSearchResult | null>(null);
  const [sortParams, setSortParams] = useState<SortParams<SortField>>({
    field: 'relevance',
    order: 'desc',
  });

  type SummaryBullets = {
    bullets: CitedBullet[];
  };

  // New Loading States
  const [loadingResults, setLoadingResults] = useState<boolean>(false);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);

  const { mutateAsync: executeQuery, isPending: isQueryLoading } = useMutation<
    SearchResponse,
    Error,
    QueryParams
  >({
    mutationFn: async (params: QueryParams) => {
      // Reset previous state
      setError(null);
      setSummary(null);
      setSummaryBullets(null);
      setResults([]);
      setLoadingSummary(params.summarize ?? false);
      setLoadingResults(true); // Start loading results
      const signal = abortControllerRef.current?.signal;
      const generator = await querySearchStream(params, signal);

      const aggregatedResponse: SearchResponse = {
        articles: [],
        filters: {
          source: [],
          documentType: [],
          dateAfter: null,
          dateBefore: null,
          company: [],
          topic: [],
          regulation: [],
          regulator: [],
          jurisdiction: [],
          sortParams: params.filters?.sortParams,
        },
        stage: 'COMPLETE',
        bullets: [],
      };

      for await (const chunk of generator) {
        if (chunk.articles) {
          aggregatedResponse.articles.push(...chunk.articles);
          setResults((prev) => [...prev, ...chunk.articles]);
          // ack that we've received the results
          setLoadingResults(false);
        }
        if (chunk.filters) {
          aggregatedResponse.filters = {
            ...aggregatedResponse.filters,
            ...chunk.filters,
          };
        }
        if (chunk.bullets) {
          aggregatedResponse.bullets = aggregatedResponse.bullets || [];
          aggregatedResponse.bullets.push(...chunk.bullets);
          setSummaryBullets({
            bullets: aggregatedResponse.bullets,
          });
        }
        if (chunk.summary) {
          aggregatedResponse.summary = chunk.summary;
          setSummary(chunk.summary);
        }
      }
      return aggregatedResponse;
    },
  });

  const fetchArticleContentMutation = useMutation<
    string[],
    Error,
    SearchResult
  >({
    mutationFn: getSearchContent,
  });

  const performQuery = useCallback(
    async (params: Omit<QueryParams, 'abortSignal'>) => {
      // Cancel any ongoing request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Initialize a new AbortController for the current request
      abortControllerRef.current = new AbortController();

      try {
        const response = await executeQuery({
          ...params,
          abortSignal: abortControllerRef.current.signal,
        });
        if (response.bullets && response.summary) {
          setLoadingSummary(false);
        }
        return response;
      } catch (error) {
        if (
          error instanceof Error &&
          (error.name === 'AbortError' || error.name === 'CanceledError')
        ) {
          console.log(
            'Search request was aborted or canceled. No error state set.',
          );
        } else {
          console.error('Error performing search query:', error);
          setError(error as Error);
        }
        throw error;
      }
    },
    [executeQuery],
  );

  /**
   * Fetches the content for a given article.
   * @param article - The article for which to fetch the content.
   * @returns The content array if available, otherwise null.
   */
  const fetchArticleContent = useCallback(
    async (article: SearchResult): Promise<string[] | null> => {
      const contentId = article.contentId;

      // Return cached content if available
      if (articleContents.has(contentId)) {
        return articleContents.get(contentId) || null;
      }

      // Return null if content is currently loading
      if (loadingArticleContents.has(contentId)) {
        return null;
      }

      // Set loading state
      setLoadingArticleContents((prev) => new Set(prev).add(contentId));

      try {
        const content = await fetchArticleContentMutation.mutateAsync(article);
        if (content) {
          setArticleContents((prev) => new Map(prev).set(contentId, content));
          console.log(`Content for "${contentId}" fetched and set.`);
          return content;
        }
        console.log(`No content returned for "${contentId}".`);
        return null;
      } catch (error) {
        console.error(`Error fetching content for "${contentId}":`, error);
        return null;
      } finally {
        // Clear loading state
        setLoadingArticleContents((prev) => {
          const newSet = new Set(prev);
          newSet.delete(contentId);
          return newSet;
        });
      }
    },
    [articleContents, loadingArticleContents, fetchArticleContentMutation],
  );

  return {
    query,
    setQuery,
    performQuery,
    fetchArticleContent,
    results,
    summary,
    summaryBullets,
    error,
    isLoading: isQueryLoading,
    loadingResults,
    loadingSummary,
    articleContents,
    loadingArticleContents,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    sortParams,
    setSortParams,
    selectedResult,
    setSelectedResult,
  };
};

export const useSearchSummarize = () => {
  // State for article summaries
  const [articleSummaries, setArticleSummaries] = useState<
    Map<string, Summary>
  >(new Map());
  const [loadingArticleSummaries, setLoadingArticleSummaries] = useState<
    Set<string>
  >(new Set());

  const summarizeMutation = useMutation<Summary, Error, SummarizeParams>({
    mutationFn: summarizeSearch,
  });

  /**
   * Fetches the summary for a given article.
   * @param article - The article for which to fetch the summary.
   * @param query - The query string associated with the search.
   * @returns The summary if available, otherwise null.
   */
  const fetchArticleSummary = useCallback(
    async (article: SearchResult, query: string): Promise<Summary | null> => {
      const contentId = article.contentId;

      // Return cached summary if available
      if (articleSummaries.has(contentId)) {
        return articleSummaries.get(contentId) || null;
      }

      // Return null if summary is currently loading
      if (loadingArticleSummaries.has(contentId)) {
        return null;
      }

      // Set loading state
      setLoadingArticleSummaries((prev) => new Set(prev).add(contentId));

      try {
        const summary = await performSummarization(article, query);
        if (summary) {
          setArticleSummaries((prev) => new Map(prev).set(contentId, summary));
          return summary;
        }

        return null;
      } catch (error) {
        if (
          error instanceof Error &&
          (error.name === 'AbortError' || error.name === 'CanceledError')
        ) {
          console.log('Summarization aborted');
        } else {
          console.error('Summarization failed', error);
        }
        return null;
      } finally {
        // Clear loading state
        setLoadingArticleSummaries((prev) => {
          const newSet = new Set(prev);
          newSet.delete(contentId);
          return newSet;
        });
      }
    },
    [articleSummaries, loadingArticleSummaries, summarizeMutation],
  );

  /**
   * Performs summarization for a given article and query.
   * @param article - The article to summarize.
   * @param query - The query string associated with the search.
   * @returns The summary result.
   */
  const performSummarization = useCallback(
    async (article: SearchResult, query: string) => {
      try {
        const result = await summarizeMutation.mutateAsync({
          query,
          contentId: article.contentId,
        });
        return result;
      } catch (error) {
        if (
          error instanceof Error &&
          (error.name === 'AbortError' || error.name === 'CanceledError')
        ) {
          console.log('Summarization aborted');
        } else {
          console.error('Summarization failed', error);
          throw error;
        }
      }
    },
    [summarizeMutation],
  );

  return {
    fetchArticleSummary,
    performSummarization,
    articleSummaries,
    loadingArticleSummaries,
    summarizeResults: summarizeMutation.data ?? { bullets: [] },
    summarizeError: summarizeMutation.error,
    isSummarizeLoading: summarizeMutation.isPending,
  };
};
