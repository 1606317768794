import Button from '@/components/Buttons/Button';
import ScrollContainer from '@/components/List/ScrollContainer';
import ProfileInputSection from '@/components/Profiles/Create/ProfileInputSection';
import ProfileJurisdictions from '@/components/Profiles/Create/ProfileJurisdictions';
import ProfileSection from '@/components/Profiles/Shared/ProfileSection';
import { PROFILE_SECTIONS } from '@/components/Profiles/Shared/constants';
import { useProfiles } from '@/hooks/useProfiles';
import { useProfilesContext } from '@/providers/profilesProvider';
import { useSearchFiltersContext } from '@/providers/searchFiltersProvider';
import { Jurisdiction } from '@/types';
import { FilterEnum } from '@/types/filter';
import { Profile } from '@/types/profiles';
import { TagIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useMemo, useState } from 'react';
import ProfileTemplates from '../Templates/ProfileTemplates';

interface Props {
  onClose: () => void;
  profile?: Profile;
}

const CreateProfileView: React.FC<Props> = ({ onClose, profile }) => {
  const isEditing = !!profile;
  const [profileName, setProfileName] = useState(profile?.name || '');
  const [regulations, setRegulations] = useState(profile?.regulations || ['']);
  const [services, setServices] = useState(profile?.services || ['']);
  const [complianceTopics, setComplianceTopics] = useState(
    profile?.complianceTopics || [''],
  );
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>(
    profile?.jurisdictions || [],
  );
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(false);

  const { createProfile, updateProfile, isLoading } = useProfiles(undefined);
  const { refetchProfiles } = useProfilesContext();
  const { filters } = useSearchFiltersContext();

  const isFormValid =
    profileName.trim() !== '' &&
    (regulations.some((item) => item.trim() !== '') ||
      services.some((item) => item.trim() !== '') ||
      complianceTopics.some((item) => item.trim() !== ''));

  const handleSubmit = async (e: React.FormEvent) => {
    if (!isFormValid) return;
    e.preventDefault();
    const profileData = {
      name: profileName,
      regulations: regulations.filter(Boolean),
      services: services.filter(Boolean),
      complianceTopics: complianceTopics.filter(Boolean),
      jurisdictions: jurisdictions.filter(Boolean),
      data: {},
      profileId: profile?.profileId,
    };
    if (isEditing) {
      await updateProfile({ profileData });
    } else {
      await createProfile({ profileData });
    }
    await refetchProfiles();
    onClose();
  };

  // Get available jurisdictions from the RAG filters
  const availableJurisdictions = useMemo(() => {
    const jurisdictionFilter = filters[FilterEnum.JURISDICTION];
    return jurisdictionFilter?.map((opt) => JSON.parse(opt.value)) || [];
  }, [filters]);

  type SectionType = 'regulations' | 'services' | 'complianceTopics';
  type SetStateFunction = (value: string[]) => void;

  const sectionStateMap = new Map<SectionType, [string[], SetStateFunction]>([
    ['regulations', [regulations, setRegulations]],
    ['services', [services, setServices]],
    ['complianceTopics', [complianceTopics, setComplianceTopics]],
  ]);

  return (
    <ScrollContainer>
      <div className="space-y-10 px-6">
        <form onSubmit={handleSubmit}>
          <div className="pb-6">
            <div className="mb-6 flex items-center justify-between pt-2">
              <div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {isEditing
                    ? 'Edit Your Business Profile'
                    : 'Setup Your Business Profile'}
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  {isEditing
                    ? 'Edit your business profile to monitor specific regulations, services, and compliance topics relevant to your business.'
                    : 'Create a business profile to monitor specific regulations, services, and compliance topics relevant to your business.'}
                </p>
              </div>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {!isEditing && (
              <ProfileTemplates
                isOpen={isTemplatesOpen}
                setIsOpen={setIsTemplatesOpen}
                onApply={(template) => {
                  setProfileName(template.name);
                  setRegulations(template.regulations);
                  setServices(template.services);
                  setComplianceTopics(template.complianceTopics);
                }}
              />
            )}

            <ProfileSection
              icon={TagIcon}
              title="Business Profile Name"
              description="Give your business profile a name that helps you identify its purpose and scope"
            >
              <div className="relative">
                <input
                  type="text"
                  id="profileName"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  className="block h-full w-full select-none appearance-none rounded-md border border-gray-200 bg-white py-3 pl-4 pr-10 text-sm text-gray-900 shadow-sm transition-all duration-200 placeholder:text-gray-400 hover:border-gray-300 hover:bg-gray-50 focus:border-gray-200 focus:bg-gray-50 focus:outline-none focus:ring-0"
                  placeholder="Enter your business profile name"
                  maxLength={50}
                  style={{
                    outline: 'none',
                    borderColor: 'transparent !important',
                  }}
                  required
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                {profileName && (
                  <button
                    type="button"
                    onClick={() => setProfileName('')}
                    className="group absolute right-2 top-1/2 -translate-y-1/2 rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-400 transition-all duration-200 group-hover:text-gray-600" />
                  </button>
                )}
              </div>
            </ProfileSection>
          </div>

          {PROFILE_SECTIONS.map((section) => {
            const sectionState = sectionStateMap.get(
              section.type as SectionType,
            );
            if (!sectionState) return null;
            const [items, setItems] = sectionState;

            return (
              <ProfileInputSection
                key={section.type}
                title={section.title}
                description={section.description}
                items={items}
                onChange={setItems}
                icon={section.icon}
              />
            );
          })}

          <div className="mt-8 border-b border-gray-900/10 pb-12">
            <ProfileJurisdictions
              jurisdictions={availableJurisdictions}
              selectedJurisdictions={jurisdictions}
              onSelectionChange={setJurisdictions}
            />
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-2">
            <Button variant="outline" size="sm" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              disabled={isLoading || !isFormValid}
            >
              {isLoading ? 'Saving...' : 'Save Profile'}
            </Button>
          </div>
        </form>
      </div>
    </ScrollContainer>
  );
};

export default CreateProfileView;
