import '@/index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from '@/App';
import reportWebVitals from '@/reportWebVitals';

import { Amplify } from 'aws-amplify'; // AWS Amplify sdk

import '@aws-amplify/ui-react/styles.css'; // We need to include styles for the login form displayed by the `withAuthenticator` higher-order component.

import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { HeroUIProvider } from '@heroui/react';

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          redirectSignIn: [process.env.REACT_APP_COGNITO_SIGNIN_URI || ''],
          redirectSignOut: [process.env.REACT_APP_COGNITO_SIGNOUT_URI || ''],
          domain: 'regsage.auth.eu-west-1.amazoncognito.com',
          providers: ['Google'],
          scopes: ['email', 'openid', 'profile'],
          responseType: 'code',
        },
      },
      userPoolId:
        process.env.REACT_APP_COGNITO_USER_POOL_ID || 'eu-west-1_JzDimkIHl',
      userPoolClientId:
        process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID ||
        'sd7gibcvb42i4ft7ig7rt20h8',
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HeroUIProvider>
        <App />
        {/* {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )} */}
      </HeroUIProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
