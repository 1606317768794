import ChatFeed from '@/components/Chat/ChatFeed';
import DocumentAsMarkdown from '@/components/DocumentAsMarkdown';
import ScrollContainer from '@/components/List/ScrollContainer';
import ValidUrl from '@/components/Utils/ValidUrl';
import SplitPanel from '@/layouts/SplitPanel';
import { useSearchContext } from '@/providers/searchProvider';
import { Article } from '@/types';
import { LinkIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useMemo } from 'react';
import { DetailViewContentSkeleton } from '../Skeletons';
import BulletsDisplay from './BulletsDisplay';

interface SearchResultArticle extends Article {
  contentId: string;
  url: string;
  title: string;
  chunks: string[];
}

const SearchResultDetailedView = () => {
  const {
    articleContents,
    loadingArticleContents,
    setSelectedResult,
    selectedResult,
    summarizeResults,
    isSummarizeLoading,
  } = useSearchContext();
  const { sourceId, result } = selectedResult || {};
  const documentArticle = useMemo((): SearchResultArticle | null => {
    const contents = articleContents.get(result?.contentId || '');
    if (!contents || contents.length === 0) return null;

    return {
      ...result!,
      chunks: contents,
    };
  }, [selectedResult, articleContents]);

  const renderContent = useCallback(() => {
    if (loadingArticleContents.has(result?.contentId || '')) {
      return <DetailViewContentSkeleton paragraphs={3} />;
    }

    if (!documentArticle) {
      return (
        <div className="flex-col p-8 text-center text-sm text-gray-800">
          <div className="">The document is not available for preview</div>
          <div>
            Please view the original document{' '}
            <ValidUrl url={result?.url || ''}>
              <p className="text-blue-500 hover:underline">here</p>
            </ValidUrl>
          </div>
        </div>
      );
    }

    return (
      <DocumentAsMarkdown
        doc={documentArticle}
        highlightIndex={Number(sourceId)}
      />
    );
  }, [documentArticle, loadingArticleContents, selectedResult, sourceId]);

  return (
    <div className="mx-auto h-full w-full">
      <SplitPanel
        leftContent={
          <div className="flex h-full w-full flex-col overflow-hidden">
            <ChatFeed
              header={
                <>
                  <div className="text-base font-medium text-gray-900">
                    {documentArticle?.title}
                  </div>
                  {isSummarizeLoading && (
                    <DetailViewContentSkeleton paragraphs={1} />
                  )}
                  {!isSummarizeLoading && summarizeResults && (
                    <BulletsDisplay
                      bullets={summarizeResults.bullets}
                      onSelectSource={(
                        contentId: string,
                        sourceId: number,
                      ) => {
                        console.log('select source', contentId, sourceId);
                        if (!selectedResult) return;
                        setSelectedResult({
                          ...selectedResult,
                          sourceId,
                        });
                      }}
                    />
                  )}
                </>
              }
              article={documentArticle}
            />
          </div>
        }
        rightContent={
          <div className="flex h-full w-full flex-col border-l border-gray-200">
            <div className="flex items-center justify-between px-4 pb-2 pt-4 shadow-sm">
              <ValidUrl url={result?.url || ''}>
                <div className="flex items-center text-blue-500 underline hover:text-blue-600">
                  <div className="text-sm font-medium">
                    {result?.title ||
                      (articleContents.get(result?.contentId || '') &&
                        articleContents
                          .get(result?.contentId || '')![0]
                          .substring(0, 50)) ||
                      ''}
                  </div>
                  <LinkIcon
                    className="ml-1.5 h-4 w-4 cursor-pointer text-gray-500 hover:text-blue-500"
                    aria-hidden="true"
                  />
                </div>
              </ValidUrl>
              <div className="flex justify-end px-2">
                <button
                  onClick={() => {
                    setSelectedResult(null);
                  }}
                  className="rounded-md bg-gray-100 p-1.5 text-gray-600 hover:bg-gray-200 hover:text-gray-900"
                >
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            <div className="flex-1 overflow-auto">
              <ScrollContainer>
                <div className="overflow-y-auto">{renderContent()}</div>
              </ScrollContainer>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SearchResultDetailedView;
