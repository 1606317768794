import { CitedBullet } from '@/types';
import { SearchSummarySkeleton } from '../Skeletons';
import BulletsDisplay from './BulletsDisplay';

const SearchSummary = ({
  isLoading,
  summary,
  bullets,
  onSelectSource,
}: {
  isLoading: boolean;
  summary: string | null;
  bullets: CitedBullet[] | null;
  onSelectSource: (contentId: string, sourceId: number) => void;
}) => {
  return (
    <div className="flex w-full flex-col px-10 text-sm text-gray-800">
      {isLoading || !bullets ? (
        <SearchSummarySkeleton />
      ) : (
        <>
          {summary && <p className="mb-4 max-w-5xl">{summary}</p>}
          <BulletsDisplay bullets={bullets} onSelectSource={onSelectSource} />
        </>
      )}
    </div>
  );
};

export default SearchSummary;
