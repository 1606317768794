interface CountryMap {
  [key: string]: string;
}

interface RegionMap {
  [key: string]: CountryMap;
}

export const EUROPE: CountryMap = {
  AL: 'Albania',
  AD: 'Andorra',
  AM: 'Armenia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BY: 'Belarus',
  BE: 'Belgium',
  BA: 'Bosnia and Herzegovina',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  GE: 'Georgia',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IS: 'Iceland',
  IE: 'Ireland',
  IT: 'Italy',
  JE: 'Jersey',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  MC: 'Monaco',
  ME: 'Montenegro',
  NL: 'Netherlands',
  MK: 'North Macedonia',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  MD: 'Moldova',
  RO: 'Romania',
  RU: 'Russia',
  SM: 'San Marino',
  RS: 'Serbia',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  UA: 'Ukraine',
  UK: 'United Kingdom',
  GB: 'United Kingdom',
  VA: 'Holy See (Vatican City)',
};

export const NORTH_AMERICA: CountryMap = {
  BS: 'Bahamas, The',
  BB: 'Barbados',
  BM: 'Bermuda',
  CA: 'Canada',
  CU: 'Cuba',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  GD: 'Grenada',
  JM: 'Jamaica',
  MX: 'Mexico',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  VC: 'Saint Vincent and the Grenadines',
  TT: 'Trinidad and Tobago',
  US: 'United States',
};

export const LATIN_AMERICA: CountryMap = {
  AR: 'Argentina',
  BO: 'Bolivia',
  BR: 'Brazil',
  CL: 'Chile',
  CO: 'Colombia',
  CR: 'Costa Rica',
  EC: 'Ecuador',
  SV: 'El Salvador',
  GT: 'Guatemala',
  HN: 'Honduras',
  NI: 'Nicaragua',
  PA: 'Panama',
  PY: 'Paraguay',
  PE: 'Peru',
  UY: 'Uruguay',
  VE: 'Venezuela',
};

export const ASIA_PACIFIC: CountryMap = {
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AU: 'Australia',
  BD: 'Bangladesh',
  BT: 'Bhutan',
  BN: 'Brunei',
  KH: 'Cambodia',
  CN: 'China',
  FJ: 'Fiji',
  IN: 'India',
  ID: 'Indonesia',
  IL: 'Israel',
  JP: 'Japan',
  KZ: 'Kazakhstan',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  MY: 'Malaysia',
  MV: 'Maldives',
  MN: 'Mongolia',
  MM: 'Myanmar',
  NP: 'Nepal',
  NZ: 'New Zealand',
  PK: 'Pakistan',
  PH: 'Philippines',
  SG: 'Singapore',
  KR: 'South Korea',
  LK: 'Sri Lanka',
  TJ: 'Tajikistan',
  TH: 'Thailand',
  TM: 'Turkmenistan',
  UZ: 'Uzbekistan',
  VN: 'Vietnam',
  WS: 'Samoa',
};

export const AFRICA: CountryMap = {
  ZA: 'South Africa',
  EG: 'Egypt',
  DZ: 'Algeria',
  MA: 'Morocco',
  NG: 'Nigeria',
  KE: 'Kenya',
  TZ: 'Tanzania',
};

export const COUNTRY_CODES_TO_NAMES: CountryMap = {
  ...EUROPE,
  ...NORTH_AMERICA,
  ...LATIN_AMERICA,
  ...ASIA_PACIFIC,
  ...AFRICA,
};

export const REGION_TO_COUNTRY_CODES: RegionMap = {
  EUROPE: EUROPE,
  NORTH_AMERICA: NORTH_AMERICA,
  LATIN_AMERICA: LATIN_AMERICA,
  ASIA_PACIFIC: ASIA_PACIFIC,
  AFRICA: AFRICA,
};
