import { Profile } from '@/types/profiles';
import { ChevronDownIcon, UserIcon } from '@heroicons/react/20/solid';
import MultiSelectDropdownMenu from '../Menus/MultiSelectDropdownMenu';
import ProfileAvatar from '../Profiles/Shared/ProfileAvatar';

interface ProfileFilterProps {
  profiles: Profile[];
  selectedProfileIds: string[];
  onSelectionChange: (selectedIds: string[]) => void;
}

export default function ProfileFilter({
  profiles,
  selectedProfileIds,
  onSelectionChange,
}: ProfileFilterProps) {
  if (!profiles.length) {
    return null;
  }

  const options = profiles.map((profile) => ({
    id: profile.profileId,
    label: (
      <div className="flex items-center">
        <ProfileAvatar name={profile.name} size="sm" className="mr-3" />
        <div className="flex flex-col items-start">
          <span className="line-clamp-1 font-medium">{profile.name}</span>
        </div>
      </div>
    ),
  }));

  return (
    <MultiSelectDropdownMenu
      label="Your Business Profiles"
      options={options}
      selectedItems={selectedProfileIds}
      onSelectionChange={onSelectionChange}
      buttonClassName="rounded-full bg-gray-100 px-3 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
      buttonContent={(selectedItems) => (
        <div className="flex items-center">
          <div className="flex -space-x-2">
            {selectedItems.length > 0 ? (
              selectedItems.slice(0, 3).map((id, index) => {
                const profile =
                  profiles.find((p) => p.profileId === id) || null;
                if (!profile) {
                  return null;
                }
                return (
                  <ProfileAvatar
                    key={id}
                    name={profile.name}
                    size="sm"
                    className={`ring-2 ring-white z-[${3 - index}]`}
                  />
                );
              })
            ) : (
              <UserIcon className="h-5 w-5 text-gray-400" />
            )}
          </div>
          <div className="ml-2 text-sm text-gray-700">
            <span className="font-medium">{selectedItems.length}</span>
            <span className="mx-1">of</span>
            <span>{profiles.length}</span>
          </div>
          <ChevronDownIcon
            className="ml-1 h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
      )}
    />
  );
}
