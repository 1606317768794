import Button from '@/components/Buttons/Button';
import { COUNTRY_CODES_TO_NAMES } from '@/consts/geo';
import { DocumentSummary, NotificationResult } from '@/types/notification';
import { BuildingLibraryIcon, ScaleIcon } from '@heroicons/react/16/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { BellIcon } from '@heroicons/react/24/outline';
import {
  CalendarBlank,
  FileText,
  ListMagnifyingGlass,
} from '@phosphor-icons/react';
import { useState } from 'react';
import Pill from '../Buttons/Pill';
import ShortDateTime from '../DateTime/ShortDateTime';
import BaseTooltip from '../Utils/BaseTooltip';
import CountryFlag from '../Utils/CountryFlag';
import RiskScore from './RiskScore';

const NUM_SHORT_BULLETS = 1;
const MAX_NUM_CITATIONS = 3;

const getBadge = (view: 'narrow' | 'wide', riskScore?: number) => {
  if (riskScore) {
    return (
      <div className="flex flex-col items-center">
        <BaseTooltip tooltipContent="Risk Score" side="bottom" showArrow>
          <RiskScore score={riskScore} />
        </BaseTooltip>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-shrink-0 items-center justify-center rounded-full bg-gray-100 text-gray-400 ${
        view === 'narrow' ? 'h-8 w-8' : 'h-10 w-10'
      } `}
    >
      <BellIcon className={`${view === 'narrow' ? 'h-5 w-5' : 'h-6 w-6'} `} />
    </div>
  );
};

function NotificationContent({
  notification,
  setSourceId,
  selectedNotification,
  setSelectedNotification,
  isOpen,
  setIsOpen,
  view,
}: {
  notification: NotificationResult;
  setSourceId: (sourceId: number) => void;
  selectedNotification: NotificationResult | null;
  setSelectedNotification: (notification: NotificationResult | null) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  view: 'narrow' | 'wide';
}) {
  const isNarrowView = view === 'narrow';

  const Summary = ({
    summary,
    version,
  }: {
    summary: DocumentSummary;
    version: 'short' | 'long';
  }) => {
    const detailedSummaries =
      version === 'long'
        ? summary?.detailedSummaries
        : (
            summary?.detailedSummaries?.[0]?.bullets?.slice(
              0,
              NUM_SHORT_BULLETS,
            ) ?? []
          ).map((bullet) => ({
            bullets: [bullet],
          }));

    return (
      <div className="flex w-full flex-col text-sm text-gray-800">
        <div className="w-full text-left">
          <p>{summary?.summary}</p>
        </div>
        {detailedSummaries.map((detailedSummary, index) => (
          <div key={index}>
            <ul className="w-full list-disc pl-4 text-left">
              {detailedSummary.bullets.map((bullet, index) => (
                <li key={index} className="w-full py-2 text-left">
                  <span className="inline-flex w-full items-start text-left">
                    {bullet.bullet}
                    {bullet.citations
                      .slice(0, MAX_NUM_CITATIONS)
                      .map((citation, index) => (
                        <Button
                          key={index}
                          variant="text"
                          size="xs"
                          className="mx-0.5 flex-shrink-0 py-0"
                          onClick={() => {
                            setSelectedNotification(notification);
                            setSourceId(Number(citation.sourceId));
                          }}
                        >
                          <BaseTooltip
                            key={index}
                            tooltipContent={
                              <div className="max-w-xs">
                                View Citation {citation.sourceId + 1}
                              </div>
                            }
                            side="top"
                            showArrow
                          >
                            <ListMagnifyingGlass
                              className="inline-block h-5 w-5 text-gray-500"
                              size={16}
                            />
                          </BaseTooltip>
                        </Button>
                      ))}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const renderPills = () => (
    <div
      className={`flex items-start space-x-2 ${
        isNarrowView ? '' : 'ml-auto pl-4'
      }`}
    >
      {notification?.doc?.country && (
        <Pill
          variant="outline"
          size="xs"
          color="gray"
          text={
            COUNTRY_CODES_TO_NAMES[notification?.doc?.country] ||
            notification?.doc?.country
          }
          startIcon={
            <CountryFlag country={notification?.doc?.country} width={16} />
          }
        />
      )}
      {notification.regulators?.[0] && (
        <Pill
          variant="outline"
          color="gray"
          size="xs"
          text={
            notification.regulators?.[0]?.shortName ||
            (notification.regulators?.[0]?.longName as string)
          }
          startIcon={<BuildingLibraryIcon className="h-3.5 w-3.5" />}
        />
      )}
      {notification.regulations?.[0] && (
        <Pill
          variant="outline"
          color="gray"
          size="xs"
          text={
            notification.regulations?.[0]?.shortName ||
            (notification.regulations?.[0]?.longName as string)
          }
          startIcon={<ScaleIcon className="h-3.5 w-3.5" />}
        />
      )}
      <Pill
        variant="outline"
        size="xs"
        color="gray"
        text={notification.documentType}
        startIcon={<FileText className="h-3.5 w-3.5" weight="fill" />}
      />
    </div>
  );

  return (
    <button
      className={`relative flex w-full items-start justify-between py-2 ${view === 'narrow' ? 'gap-x-3 px-1' : 'gap-x-4 px-2'} `}
      onClick={() => {
        setSelectedNotification(notification);
      }}
    >
      <div className="flex flex-shrink-0 items-start">
        {getBadge(view, notification.riskScore)}
      </div>
      <div
        className={`flex w-full flex-col items-start ${
          isNarrowView ? '' : 'gap-x-4'
        }`}
      >
        {isNarrowView && <div className="mb-2">{renderPills()}</div>}
        <div className={`flex w-full items-start justify-between gap-x-2`}>
          <div className="flex-grow">
            <p className="max-w-full text-left text-sm font-medium leading-6 text-gray-900 sm:max-w-6xl">
              {notification?.title}
            </p>
            <p className="mt-1 flex items-center gap-x-1 text-left text-sm text-gray-500">
              <CalendarBlank className="h-4 w-4" />
              <ShortDateTime dateTime={notification.datePublished} />
            </p>
          </div>
          {!isNarrowView && renderPills()}
        </div>
        <div
          className={`flex w-full max-w-full flex-grow text-xs leading-5 text-gray-500 ${
            isNarrowView ? 'mt-2' : 'mt-2'
          } sm:max-w-6xl`}
        >
          <div className="w-full whitespace-pre-wrap pr-20 text-left">
            {notification?.summary && (
              <Summary
                summary={notification?.summary}
                version={isOpen ? 'long' : 'short'}
              />
            )}
          </div>
        </div>
        <div className="mt-2 flex w-full justify-center">
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevents the click from propagating to the parent div
              setIsOpen(!isOpen);
            }}
            className="text-xs text-gray-500 hover:text-gray-700"
          >
            {isOpen ? (
              <ChevronUpIcon
                className="h-5 w-5 flex-none"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 flex-none"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </div>
    </button>
  );
}

export default function NotificationItem({
  notification,
  selectedNotification,
  setSelectedNotification,
  setSourceId,
  view,
}: {
  notification: NotificationResult;
  selectedNotification: NotificationResult | null;
  setSelectedNotification: (notification: NotificationResult | null) => void;
  setSourceId: (sourceId: number) => void;
  view: 'narrow' | 'wide';
}) {
  const [isOpen, setIsOpen] = useState(
    selectedNotification?.contentId === notification.contentId,
  );

  return (
    <div className="w-full">
      <NotificationContent
        notification={notification}
        setSourceId={setSourceId}
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        view={view}
      />
    </div>
  );
}
