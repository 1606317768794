import ContentLoader from 'react-content-loader';

const PARAGRAPH_HEIGHT_PX = 200;
const LINE_HEIGHT_PX = 20;
const BG_COLOR = '#d2d2d2';
const FG_COLOR = '#ecebeb';

export const DetailViewContentSkeleton = ({
  paragraphs,
  ...props
}: {
  paragraphs: number;
}) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={paragraphs * PARAGRAPH_HEIGHT_PX}
    className="mt-3 p-1"
    backgroundColor={BG_COLOR}
    foregroundColor={FG_COLOR}
    preserveAspectRatio="none"
    {...props}
  >
    {[...Array(paragraphs)].map((_, paragraphIndex) => {
      const lines = 8;
      const startY = paragraphIndex * PARAGRAPH_HEIGHT_PX;

      return [...Array(lines)].map((_, lineIndex) => (
        <rect
          key={`p${paragraphIndex}-${lineIndex}`}
          x="0"
          y={startY + lineIndex * LINE_HEIGHT_PX}
          rx="3"
          ry="3"
          width={`${Math.floor(Math.random() * 30) + 70}%`}
          height="8"
        />
      ));
    })}
  </ContentLoader>
);

export const SearchResultItemSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={120}
    backgroundColor={BG_COLOR}
    foregroundColor={FG_COLOR}
    preserveAspectRatio="none"
    {...props}
  >
    {/* Top row - Pills */}
    <rect x="16" y="8" rx="12" ry="12" width="120" height="24" />
    <rect x="146" y="8" rx="12" ry="12" width="140" height="24" />
    <rect x="296" y="8" rx="12" ry="12" width="100" height="24" />
    {/* Date (right-aligned) */}
    <rect x="calc(100% - 96px)" y="12" rx="3" ry="3" width="80" height="16" />
    {/* Title */}
    <rect x="16" y="48" rx="3" ry="3" width="90%" height="16" />
    {/* Topics */}
    <rect x="16" y="80" rx="3" ry="3" width="16" height="16" /> {/* Icon */}
    <rect x="40" y="84" rx="3" ry="3" width="25%" height="8" />
    <rect x="calc(25% + 50px)" y="84" rx="3" ry="3" width="25%" height="8" />
    <rect x="calc(50% + 60px)" y="84" rx="3" ry="3" width="25%" height="8" />
  </ContentLoader>
);

export const SearchResultsListSkeleton = (props: any) => (
  <div className="mx-auto flex w-full flex-col overflow-hidden">
    <div className="w-full flex-1 space-y-4">
      {/* Render multiple SearchResultItemSkeletons */}
      {[...Array(10)].map((_, index) => (
        <div key={index} className="w-full border-b pb-4">
          <SearchResultItemSkeleton />
        </div>
      ))}
    </div>
  </div>
);

export const SearchSummarySkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={220}
    backgroundColor={BG_COLOR}
    foregroundColor={FG_COLOR}
    preserveAspectRatio="none"
    className="mb-4 rounded-lg"
    {...props}
  >
    {/* Summary paragraph - 4 lines with varying widths for natural look */}
    <rect x="0" y="12" rx="4" ry="4" width="90%" height="12" />
    <rect x="0" y="36" rx="4" ry="4" width="85%" height="12" />
    <rect x="0" y="60" rx="4" ry="4" width="80%" height="12" />
    <rect x="0" y="84" rx="4" ry="4" width="75%" height="12" />

    {/* Bullet points section with enhanced visibility */}
    {[...Array(3)].map((_, index) => (
      <>
        <circle cx="32" cy={132 + index * 30} r="6" fill="#a0a0a0" />
        {/* Bullet point text with varying widths */}
        <rect
          x="48"
          y={126 + index * 30}
          rx="4"
          ry="4"
          width={`${80 - index * 15}%`}
          height="12"
        />
      </>
    ))}
  </ContentLoader>
);
