import { COUNTRY_CODES_TO_NAMES } from '@/consts/geo';
import React from 'react';
import Flag from 'react-world-flags';

interface CountryFlagProps {
  /** Country code (2 letter ISO) or full country name */
  country: string;
  /** Optional fallback element when flag cannot be found */
  fallback?: React.ReactNode;
  /** Optional height in pixels */
  height?: string | number;
  /** Optional width in pixels */
  width?: string | number;
  /** Additional className for styling */
  className?: string;
}

export const CountryFlag: React.FC<CountryFlagProps> = ({
  country,
  fallback = null,
  height,
  width,
  className,
}) => {
  const mapEdgeCases = (countryCode: string) => {
    if (countryCode === 'UK') {
      return 'GB';
    }
    return countryCode;
  };
  // Helper function to get country code from name or return the code if already a code
  const getCountryCode = (countryNameOrCode: string): string => {
    // If input is already a country code (2 letters), return it uppercase
    if (countryNameOrCode.length === 2) {
      return countryNameOrCode.toUpperCase();
    }

    // Search for country code by name
    const entry = Object.entries(COUNTRY_CODES_TO_NAMES).find(
      ([_, name]) => name.toLowerCase() === countryNameOrCode.toLowerCase(),
    );
    return entry ? entry[0] : '';
  };

  const countryCode = mapEdgeCases(getCountryCode(country));

  return (
    <Flag
      code={countryCode}
      fallback={fallback}
      height={height}
      width={width}
      className={className}
    />
  );
};

export default CountryFlag;
