import Button from '@/components/Buttons/Button';
import BaseTooltip from '@/components/Utils/BaseTooltip';
import { useSearchContext } from '@/providers/searchProvider';
import { CitedBullet } from '@/types';
import { ListMagnifyingGlass } from '@phosphor-icons/react';

const MAX_NUM_CITATIONS = 3;

const BulletsDisplay = ({
  bullets,
  onSelectSource,
}: {
  bullets: CitedBullet[];
  onSelectSource: (contentId: string, sourceId: number) => void;
}) => {
  const { results } = useSearchContext();

  return (
    <ul className="w-full list-disc pl-4 text-left text-sm text-gray-800">
      {bullets.map((bullet, index) => (
        <li key={index} className="w-full py-2 text-left">
          <span className="flex w-full items-start text-left">
            <span className="w-7/8 flex-grow">{bullet.bullet}</span>
            <span className="w-1/8 flex flex-shrink-0">
              {bullet.citations
                .slice(0, MAX_NUM_CITATIONS)
                .map((citation, index) => (
                  <Button
                    key={index}
                    variant="text"
                    size="xs"
                    className="mx-0.5 flex-shrink-0 py-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (citation.contentId) {
                        onSelectSource(
                          citation.contentId,
                          Number(citation.sourceId),
                        );
                      }
                    }}
                  >
                    <BaseTooltip
                      key={index}
                      tooltipContent={
                        <div className="flex max-w-xs">
                          <span className="truncate">
                            {
                              results?.find(
                                (result) =>
                                  result.contentId === citation.contentId,
                              )?.title
                            }
                          </span>
                          <span className="ml-1 flex-shrink-0">
                            [{Number(citation.sourceId) + 1}]
                          </span>
                        </div>
                      }
                      side="top"
                      showArrow
                    >
                      <ListMagnifyingGlass
                        className="inline-block h-5 w-5 text-gray-500"
                        size={16}
                      />
                    </BaseTooltip>
                  </Button>
                ))}
            </span>
          </span>
        </li>
      ))}
    </ul>
  );
};

export default BulletsDisplay;
