import List from '@/components/List/List';
import ScrollContainer from '@/components/List/ScrollContainer';
import { SearchResult, SelectedSearchResult } from '@/types/search';
import { InboxIcon } from '@heroicons/react/24/outline';
import { memo, useEffect, useRef, useState } from 'react';
import SearchResultItem from './SearchResultItem';

const NoSearchResults = memo(() => (
  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
    <InboxIcon className="mb-4 h-12 w-12 text-gray-400" />
    <p className="text-lg font-medium">{`No search results found`}</p>
    <p className="text-md mt-2">
      {'Please try updating your filters or check back later for updates'}
    </p>
  </div>
));
NoSearchResults.displayName = 'NoSearchResults';

const SearchResults = memo(
  ({
    searchResults,
    selectedSearchResult,
    setSelectedSearchResult,
    selectedRef,
  }: {
    searchResults: SearchResult[];
    selectedSearchResult: SelectedSearchResult | null;
    setSelectedSearchResult: (
      selectedResult: SelectedSearchResult | null,
    ) => void;
    selectedRef: React.RefObject<HTMLDivElement>;
  }) => {
    return (
      <ScrollContainer>
        <List
          items={searchResults}
          renderItem={(searchResult) => (
            <div
              ref={
                selectedSearchResult?.result === searchResult
                  ? selectedRef
                  : null
              }
              className="w-full"
              key={searchResult.contentId}
            >
              <SearchResultItem
                searchResult={searchResult}
                selectedSearchResult={selectedSearchResult}
                setSelectedSearchResult={setSelectedSearchResult}
              />
            </div>
          )}
          classes={{
            li: `hover:bg-gray-50 py-2 px-4`,
          }}
        />
      </ScrollContainer>
    );
  },
);
SearchResults.displayName = 'SearchResults';

export default function SearchResultList({
  searchResults,
  selectedSearchResult,
  setSelectedSearchResult,
}: {
  searchResults: SearchResult[];
  selectedSearchResult: SelectedSearchResult | null;
  setSelectedSearchResult: (
    selectedResult: SelectedSearchResult | null,
  ) => void;
}) {
  const [sourceId, setSourceId] = useState<number | null>(null);
  const selectedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedSearchResult && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedSearchResult]);

  return (
    <div className="h-full w-full flex-col">
      <SearchResults
        searchResults={searchResults}
        selectedSearchResult={selectedSearchResult}
        setSelectedSearchResult={setSelectedSearchResult}
        selectedRef={selectedRef}
      />
    </div>
  );
}
