import Accordion from '@/components/Filters/Accordion';
import SearchFilter from '@/components/Filters/SearchFilter';
import VirtualizedList from '@/components/List/VirtualizedList';
import {
  Filter,
  FilterDisplayName,
  FilterKey,
  FilterOption,
} from '@/types/filter';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@heroui/react';
import React from 'react';

const MAX_OPTIONS_TO_SHOW_PILLS = 5;
const NUM_OPTIONS_TO_SHOW_SEARCH = 10;

interface FilterOptionProps {
  section: Filter;
  option: FilterOption;
  handleSelect: (filterType: FilterKey, filterOption: FilterOption) => void;
  handleDeselect: (filterType: FilterKey, filterOption: FilterOption) => void;
  onOptionClick?: (option: { section: Filter; option: FilterOption }) => void;
}

const SelectOption: React.FC<FilterOptionProps> = ({
  section,
  option,
  handleSelect,
  handleDeselect,
  onOptionClick,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      handleSelect(section.id as FilterKey, option);
    } else {
      handleDeselect(section.id as FilterKey, option);
    }
    if (onOptionClick) onOptionClick({ section, option });
  };

  return (
    <div className="flex w-full min-w-0 items-center rounded-md py-2 hover:bg-gray-200">
      <input
        id={`filter-${section.id}-${option.value}`}
        name={`${section.id}[]`}
        value={option.value}
        type="checkbox"
        checked={option.checked}
        className="h-4 w-4 flex-shrink-0 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-blue-500"
        onChange={handleChange}
      />
      <Tooltip
        key={option.value}
        color="primary"
        content={option.label}
        showArrow
        placement="right"
        delay={300}
        closeDelay={0}
        shouldCloseOnBlur
        isDismissable
      >
        <label
          htmlFor={`filter-${section.id}-${option.value}`}
          className="ml-1.5 min-w-0 flex-1 cursor-pointer text-sm text-gray-600"
        >
          <span className="block truncate">{option.label}</span>
        </label>
      </Tooltip>
    </div>
  );
};

interface SelectAllOptionProps {
  id: FilterKey;
  options: FilterOption[];
  handleSelectAll: (filterType: FilterKey) => void;
  handleDeselectAll: (filterType: FilterKey) => void;
}

const SelectAllOption: React.FC<SelectAllOptionProps> = ({
  id,
  options,
  handleSelectAll,
  handleDeselectAll,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      handleSelectAll(id);
    } else {
      handleDeselectAll(id);
    }
  };

  return (
    <div className="flex items-center">
      <input
        id={`filter-${id}-all`}
        name={`${id}[]`}
        value="all"
        checked={options.every((o) => o.checked)}
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
        onChange={handleChange}
      />
      <label
        htmlFor={`filter-${id}-all`}
        className="ml-3 text-sm text-gray-600"
      >
        Select All
      </label>
    </div>
  );
};

interface SelectedOptionPillProps {
  label: string;
  onRemove: () => void;
}

const SelectedOptionPill: React.FC<SelectedOptionPillProps> = ({
  label,
  onRemove,
}) => (
  <div className="inline-flex w-fit max-w-full items-center gap-1 truncate whitespace-nowrap rounded-full bg-gray-200 px-2 py-1 text-xs text-gray-700">
    <button
      onClick={onRemove}
      className="flex w-full items-center gap-1 text-gray-700 hover:text-gray-900"
    >
      <XMarkIcon className="h-4 w-4 flex-shrink-0" />
      <span className="truncate">{label}</span>
    </button>
  </div>
);

// Main Component
interface SideBarFiltersProps {
  filters: Record<FilterKey, FilterOption[]>;
  header?: JSX.Element;
  handleDeselectAll: (filterType: FilterKey) => void;
  handleSelectAll: (filterType: FilterKey) => void;
  handleDeselect: (filterType: FilterKey, filterOption: FilterOption) => void;
  handleSelect: (filterType: FilterKey, filterOption: FilterOption) => void;
}

const SideBarFilters: React.FC<SideBarFiltersProps> = ({
  header,
  filters,
  handleDeselectAll,
  handleSelectAll,
  handleDeselect,
  handleSelect,
}) => {
  // Add state for filter searches
  const [filterSearches, setFilterSearches] = React.useState<{
    [key: string]: string;
  }>(
    Object.fromEntries(
      Object.keys(filters).map((filterKey) => [filterKey, '']),
    ),
  );

  // Function to update filter search
  const updateFilterSearch = (filterId: string, search: string) => {
    setFilterSearches((prev) => ({ ...prev, [filterId]: search }));
  };

  // Function to filter options based on search
  const filterOptions = (options: FilterOption[], search: string) => {
    if (!search) return options;
    const lowerSearch = search.toLowerCase();
    return options.filter((option) =>
      option.label.toLowerCase().includes(lowerSearch),
    );
  };

  const clearAllFilters = () => {
    const filterKeys = Object.keys(filters) as FilterKey[];
    for (const key of filterKeys) {
      handleDeselectAll(key);
    }
    setFilterSearches((prev) =>
      Object.fromEntries(Object.keys(prev).map((key) => [key, ''])),
    );
  };

  return (
    <div className="flex max-h-full flex-col">
      <div className="flex h-full flex-1 flex-col overflow-y-auto">
        <main className="flex-1 px-2 sm:px-4">
          {!!header && (
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
              <div className="flex items-center">{header}</div>
            </div>
          )}
          <button
            className="font-base inline-flex items-center rounded-md pt-4 text-sm text-gray-600 hover:underline"
            onClick={clearAllFilters}
          >
            Clear Filters
          </button>
          <div className="h-full">
            <form className="hidden h-full lg:block">
              {Object.keys(filters).map((filterKey) => {
                const section: Filter = {
                  id: filterKey as FilterKey,
                  name: FilterDisplayName[filterKey as FilterKey],
                  options: filters[filterKey as FilterKey],
                  defaultOpen: false,
                };

                // Only render the Accordion if the options array is valid and has length > 0
                if (!section.options || section.options.length === 0) {
                  return null;
                }

                return (
                  <Accordion
                    header={
                      <div className="flex flex-col gap-3">
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-gray-900">
                            {section.name}
                          </span>
                        </div>
                      </div>
                    }
                    key={section.id}
                    defaultOpen={section.defaultOpen}
                    closedChildren={
                      <div className="flex max-w-full flex-wrap gap-1.5 pt-3 font-normal">
                        {section.options
                          .filter((o) => o.checked)
                          .slice(0, MAX_OPTIONS_TO_SHOW_PILLS)
                          .map((o) => (
                            <SelectedOptionPill
                              key={o.value}
                              label={o.label}
                              onRemove={() => handleDeselect(section.id, o)}
                            />
                          ))}
                        {section.options.filter((o) => o.checked).length >
                          MAX_OPTIONS_TO_SHOW_PILLS && (
                          <span className="text-sm text-gray-500">
                            {section.options.filter((o) => o.checked).length -
                              MAX_OPTIONS_TO_SHOW_PILLS}{' '}
                            more active
                          </span>
                        )}
                      </div>
                    }
                  >
                    <div className="space-y-4">
                      {section.options.length > NUM_OPTIONS_TO_SHOW_SEARCH && (
                        <SearchFilter
                          query={filterSearches[section.id]}
                          setQuery={(search: string) =>
                            updateFilterSearch(section.id, search)
                          }
                        />
                      )}
                      <VirtualizedList
                        height={300}
                        items={filterOptions(
                          section.options,
                          filterSearches[section.id],
                        ).map((option) => (
                          <SelectOption
                            key={option.value}
                            section={section}
                            option={option}
                            handleSelect={handleSelect}
                            handleDeselect={handleDeselect}
                          />
                        ))}
                      />
                      <SelectAllOption
                        id={section.id}
                        options={section.options}
                        handleSelectAll={handleSelectAll}
                        handleDeselectAll={handleDeselectAll}
                      />
                    </div>
                  </Accordion>
                );
              })}
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SideBarFilters;
