import { navigationMap } from '@/consts/navigation';
import { User } from '@/types/users';
import { useQueryClient } from '@tanstack/react-query';
import {
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  GetCurrentUserOutput,
  resetPassword,
  ResetPasswordOutput,
  signIn,
  SignInOutput,
  signOut,
  signUp,
  SignUpInput,
  SignUpOutput,
} from 'aws-amplify/auth';

interface SignInProps {
  username: string;
  password: string;
}

interface SignUpProps {
  username: string;
  password: string;
  email: string;
}

type BaseResponse = { success: boolean; error?: string };
type SignInResponse = Partial<SignInOutput> & BaseResponse;
type ResetPasswordResponse = Partial<ResetPasswordOutput> & BaseResponse;
type SignUpResponse = Partial<SignUpOutput> & BaseResponse;

export async function handleGetSession() {
  try {
    const session = await fetchAuthSession();
    if (session) {
      return session;
    }
  } catch (error) {
    console.log('error getting session: ', error);
  }
}

const useAuth = () => {
  const queryClient = useQueryClient();

  async function handleSignOut(): Promise<BaseResponse> {
    try {
      queryClient.invalidateQueries();
      localStorage.clear();
      await signOut();
      // force reload and redirect to login to clear any stale data
      window.location.href = navigationMap.login.href;
      return { success: true };
    } catch (error) {
      console.log('error signing out: ', error);
      return {
        success: false,
        error:
          error instanceof Error
            ? error.message
            : 'An error occurred. Please try again.',
      };
    }
  }

  async function handleSignIn({
    username,
    password,
  }: SignInProps): Promise<SignInResponse> {
    try {
      const res = await signIn({
        username,
        password,
      });
      return { ...res, success: true };
    } catch (error) {
      return {
        success: false,
        error:
          error instanceof Error
            ? error.message
            : 'An error occurred. Please try again.',
      };
    }
  }

  async function handleSignUp({
    password,
    email,
  }: SignUpProps): Promise<SignUpResponse> {
    try {
      // Check if email contains uppercase characters
      if (email !== email.toLowerCase()) {
        return {
          success: false,
          error: 'Email must be in lowercase.',
        };
      }

      const signUpInput: SignUpInput = {
        username: email.toLowerCase(), // Ensure username is lowercase
        password,
        options: {
          autoSignIn: true,
          userAttributes: {
            email: email.toLowerCase(), // Ensure email is lowercase
          },
        },
      };
      const res = await signUp(signUpInput);
      return { ...res, success: true };
    } catch (error) {
      return {
        success: false,
        error:
          error instanceof Error
            ? error.message
            : 'An error occurred. Please try again.',
      };
    }
  }

  async function handleGetCurrentUser(): Promise<User | undefined> {
    try {
      const user: GetCurrentUserOutput = await getCurrentUser();
      const session = await handleGetSession();
      if (user && session) {
        return {
          username: user.username,
          userId: user.userId,
          email: session.tokens?.idToken?.payload.email as string,
        };
      }
    } catch (error) {
      // TODO: handle error on not signed in
    }
  }

  async function getUserId(): Promise<string | undefined> {
    try {
      const user = await handleGetCurrentUser();
      return user?.userId;
    } catch (error) {
      console.log('error getting user id: ', error);
    }
  }

  async function handleRequestPasswordReset(
    email: string,
  ): Promise<ResetPasswordResponse> {
    try {
      const res = await resetPassword({ username: email });
      return { ...res, success: true };
    } catch (error) {
      return {
        success: false,
        error:
          error instanceof Error
            ? error.message
            : 'An error occurred. Please try again.',
      };
    }
  }

  async function handleConfirmResetPassword(
    email: string,
    code: string,
    newPassword: string,
  ): Promise<BaseResponse> {
    try {
      await confirmResetPassword({
        username: email,
        newPassword,
        confirmationCode: code,
      });
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error:
          error instanceof Error
            ? error.message
            : 'An error occurred. Please try again.',
      };
    }
  }

  return {
    signIn: handleSignIn,
    signOut: handleSignOut,
    getSession: handleGetSession,
    getCurrentUser: handleGetCurrentUser,
    getUserId,
    requestPasswordReset: handleRequestPasswordReset,
    confirmResetPassword: handleConfirmResetPassword,
    signUp: handleSignUp,
  };
};

export default useAuth;
