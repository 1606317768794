import { countryCodeToName } from '@/lib/utils';
import { Jurisdiction } from '@/types';
import { GlobeAltIcon, XMarkIcon } from '@heroicons/react/24/outline';
import * as Scroll from '@radix-ui/react-scroll-area';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Switch from '../../Buttons/Switch';

interface Props {
  jurisdictions: Jurisdiction[];
  selectedJurisdictions?: Jurisdiction[];
  onSelectionChange?: (selected: Jurisdiction[]) => void;
}

const ProfileJurisdictions: React.FC<Props> = ({
  jurisdictions,
  selectedJurisdictions = [],
  onSelectionChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountryCodes, setSelectedCountryCodes] = useState<
    Set<string>
  >(
    new Set(
      selectedJurisdictions
        .map((j) => j.country)
        .filter((c): c is string => !!c),
    ),
  );

  const sortedJurisdictionData = useMemo(() => {
    const uniqueCountryCodes = Array.from(
      new Set(
        jurisdictions.map((j) => j.country).filter((c): c is string => !!c),
      ),
    );

    const countryData = uniqueCountryCodes
      .map((code) => {
        const name = countryCodeToName(code);
        return name ? ([code, name] as const) : null;
      })
      .filter((item): item is [string, string] => item !== null)
      .sort((a, b) => a[1].localeCompare(b[1]));

    const selected = countryData.filter(([code]) =>
      selectedCountryCodes.has(code),
    );
    const unselected = countryData.filter(
      ([code]) => !selectedCountryCodes.has(code),
    );

    return [...selected, ...unselected];
  }, [jurisdictions, selectedCountryCodes]);

  const filteredCountries = useMemo(() => {
    if (!searchTerm) return sortedJurisdictionData;
    const regex = new RegExp(searchTerm, 'i');
    return sortedJurisdictionData.filter(([, name]) => regex.test(name));
  }, [searchTerm, sortedJurisdictionData]);

  const handleToggle = (countryCode: string) => {
    const updatedSelections = new Set(selectedCountryCodes);
    if (updatedSelections.has(countryCode)) {
      updatedSelections.delete(countryCode);
    } else {
      updatedSelections.add(countryCode);
    }
    setSelectedCountryCodes(updatedSelections);

    const selectedJurisdictions = jurisdictions.filter(
      (j) => j.country && updatedSelections.has(j.country),
    );
    onSelectionChange?.(selectedJurisdictions);
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-2">
      {/* Left Column - Title and Description */}
      <div className="flex">
        {/* Icon Column */}
        <div className="mr-3">
          <span className="inline-flex rounded-lg bg-gray-100 p-2 text-gray-700 ring-4 ring-white">
            <GlobeAltIcon className="h-[18px] w-[18px]" aria-hidden="true" />
          </span>
        </div>

        {/* Title and Description Column */}
        <div>
          <h3 className="text-base font-medium text-gray-900">
            Jurisdictions
          </h3>
          <p className="mt-3 text-sm/6 text-gray-600">
            Select the jurisdictions you want to monitor for regulatory
            updates. If none are selected, all jurisdictions will be monitored.
          </p>
        </div>
      </div>

      {/* Right Column - Search and List */}
      <div className="w-full rounded-lg bg-white shadow-sm">
        <div className="relative">
          <input
            type="text"
            placeholder="Search by country"
            className="block h-full w-full select-none appearance-none rounded-md border border-gray-200 bg-white py-3 pl-4 pr-10 text-sm text-gray-900 shadow-sm transition-all duration-200 placeholder:text-gray-400 hover:border-gray-300 hover:bg-gray-50 focus:border-gray-200 focus:bg-gray-50 focus:outline-none focus:ring-0"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              outline: 'none',
              borderColor: 'transparent !important',
            }}
          />
          {searchTerm && (
            <button
              type="button"
              onClick={() => setSearchTerm('')}
              className="group absolute right-2 top-1/2 -translate-y-1/2 rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
            >
              <XMarkIcon className="h-5 w-5 text-gray-400 transition-all duration-200 group-hover:text-gray-600" />
            </button>
          )}
        </div>

        <Scroll.Root className="mt-4 h-full w-full overflow-hidden rounded">
          <Scroll.Viewport className="h-full max-h-[400px] w-full rounded">
            <ul className="space-y-2">
              {filteredCountries.map(([code, name]) => {
                const isSelected = selectedCountryCodes.has(code);

                return (
                  <li
                    key={code}
                    className="list-none transition-all duration-300 ease-in-out"
                  >
                    <button
                      type="button"
                      className={classNames(
                        'flex w-full cursor-pointer items-center justify-between rounded-lg p-4 transition-colors duration-200',
                        {
                          'bg-gray-200 text-gray-700': isSelected,
                          'bg-gray-50 text-gray-700 hover:bg-gray-100':
                            !isSelected,
                        },
                      )}
                      onClick={() => handleToggle(code)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleToggle(code);
                        }
                      }}
                    >
                      <div className="font-medium">{name}</div>
                      <Switch
                        checked={isSelected}
                        onChange={() => handleToggle(code)}
                      />
                    </button>
                  </li>
                );
              })}
            </ul>
          </Scroll.Viewport>
          <Scroll.Scrollbar
            className="flex touch-none select-none bg-gray-200/10 p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col hover:bg-gray-200"
            orientation="vertical"
          >
            <Scroll.Thumb className="relative flex-1 rounded-[10px] bg-gray-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
          </Scroll.Scrollbar>
          <Scroll.Corner className="bg-black" />
        </Scroll.Root>
      </div>
    </div>
  );
};

export default ProfileJurisdictions;
